import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Over the past few weeks, our team has spoken with dozens of traders,
LPs, developers and community members to learn about their experiences
interacting with Starswap.`}</p>
    <p>{`Going forward, we want to share regular updates with the community. This
post marks the first in a series highlighting product developments that
improve the overall user experience.`}</p>
    <p>{`Today, we are excited to announce the release of six new features and
improvements:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Auto Fee Tier Selection`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Liquidity Range Charts`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Create Proposal UI`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`30 Language Translations and crowdsourcing tool`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Starswap Help Center`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Docs improvements and new landing page`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "auto-fee-tier-selection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#auto-fee-tier-selection",
        "aria-label": "auto fee tier selection permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Auto Fee Tier Selection`}</h1>
    <p>{`In our conversation with liquidity providers, many expressed uncertainty
when selecting their fee tier. Which fee tier is optimal? Why not always
pick the highest one?`}</p>
    <p>{`To help LPs select the right fee tier, the app now automatically
defaults to the fee tier with the most liquidity. This method leverages
the wisdom of the market to suggest a reasonable fee tier for most LP
strategies. LPs can always manually select fee tiers by clicking the
‘edit’ button to reveal additional fee tiers and their respective
liquidity allocation percentages.`}</p>
    <p><img parentName="p" {...{
        "src": "/06059d713e2ca738afc560df3f2d9c91/lp1.gif",
        "alt": null
      }}></img></p>
    <p><em parentName="p">{`The 0.30% fee tier is auto-selected because it holds 82% of liquidity
across all v3 ETH-USDC pairs.`}</em></p>
    <p>{`Fee tier selection is nuanced and in certain scenarios an LP may choose
a tier with lower TVL (e.g. ETH/DAI 0.05%). That said, we believe TVL is
a good heuristic for the average user.`}</p>
    <p>{`Alongside the Starswap community, the Starswap Labs team will continue to
monitor the efficacy of this metric and we welcome additional feedback.`}</p>
    <h1 {...{
      "id": "liquidity-range-charts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#liquidity-range-charts",
        "aria-label": "liquidity range charts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Range Charts`}</h1>
    <p>{`Starswap v3 introduces the concept of Concentrated Liquidity — a major
improvement from previous AMM designs, LPs can now selectively allocate
their liquidity between minimum and maximum price ranges, earning a
higher percentage of trading fees with less capital in the process.`}</p>
    <p>{`In our user research interviews, we heard users rely on alternative
tools and charts to select their price ranges. Many users flipped back
and forth between the Starswap App and Starswap Info to view the liquidity
distribution for a pair.`}</p>
    <p>{`As of today, the LP interface will display the real-time distribution of
liquidity in the selected pool, providing insight into the aggregate
market’s opinion as to how liquidity should be best allocated. LPs can
use the range sliders to adjust their minimum and maximum prices
alongside the pre-existing manual input fields.`}</p>
    <p><img parentName="p" {...{
        "src": "/dfe17b3572b93bd14ddac8aca77b75cc/lp2.gif",
        "alt": null
      }}></img></p>
    <p><em parentName="p">{`Liquidity in the ETH-USDC 0.30% pair is increasingly more concentrated
around the mid-price.`}</em></p>
    <p>{`We hope that this data visualization will help users understand the
concept of a price range and quickly select a range that fits their risk
preferences. For more guidance, check out this `}<a parentName="p" {...{
        "href": "https://help.starswap.xyz/en/articles/5391541-providing-liquidity-on-Starswap-v3"
      }}><em parentName="a">{`How To
Guide`}</em></a>{`.`}</p>
    <h1 {...{
      "id": "create-proposal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#create-proposal",
        "aria-label": "create proposal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create Proposal`}</h1>
    <p>{`Starswap’s governance system requires participants to submit executable
code alongside their written proposal. To date, this has limited the
number of potential proposal participants to those that are intimately
familiar with the inner workings of Solidity.`}</p>
    <p>{`Now that the proposal threshold has been reduced from 10m UNI to 2.5m
delegated UNI, we believe it is especially important that non-technical
delegates can actively participate in proposal submission.`}</p>
    <p>{`We have now introduced the addition of a ‘Create Proposal’ feature in
the governance interface. Users can select a proposed action (Transfer,
Approve), a target address, and a target asset to accompany their
proposal text.`}</p>
    <p><img parentName="p" {...{
        "src": "/8d66e618003efee8b208d8ff5add6f77/create.gif",
        "alt": null
      }}></img></p>
    <p>{`Over the coming weeks we will introduce additional actions including:
updating the ENS; updating the v3 license, and creating liquidity mining
programs and more.`}</p>
    <h1 {...{
      "id": "translations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#translations",
        "aria-label": "translations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Translations`}</h1>
    <p><img parentName="p" {...{
        "src": "/497ac4d0398bb8d568b01342f8e08452/translate.gif",
        "alt": null
      }}></img></p>
    <p>{`The Starswap Protocol is global financial infrastructure, serving users
from all around the world.`}</p>
    <p>{`To better support our international user base, the Starswap Interface now
includes over 30 languages. The default language is set according to
your browser’s language settings, but this can be changed manually
through the dropdown menu.`}</p>
    <p>{`If you would like to contribute to translation quality, please visit the
`}<a parentName="p" {...{
        "href": "https://crowdin.com/project/Starswap-interface"
      }}><em parentName="a">{`Crowdin Starswap translations
hub`}</em></a>{`. Anyone can improve
the translations by making an account on Crowdin and submitting changes.
You can also start translations for new languages that may not be
currently supported on the interface.`}</p>
    <h1 {...{
      "id": "help-center",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#help-center",
        "aria-label": "help center permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Help Center`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b69d0b2408f9a21cc031c536eebc840f/29007/help.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC80lEQVQ4y2WQ709TVxjHryZzCkRoLZ0WZdFpTCRA2dCk4p9gjFtC2SqJMeGF9raY6GZgOl+MZmjQKEhbrAL7h5ZlL5apIJrooKDY9v44556f3+XeIoP4JJ/ce55zvp/7nGvY79ZOKEXGAeQ+IiVyYgMu6rCNJ9+yJxRyUtczXGB8eWXthDE2dmdwarKI/IMCCvenMVMqofCkhJnZJyjNzWF2/vdNns7PB73Hc7PBfuFxCQ8eTmLi3gTG707gl9u3B42Gpmj/noZGNDbsYc179/K29ja+/1CMH/zqy4ADhw9tvseOtPMv2mN8XyzKwwdaeWu0jTc2tfAduz9jOz/fhZ27G/qN5lBTMhxpQSjSrELhFt3R0YHeUyfxTW8v4j09iH9dp7snjq6eODrjXejs7kRndxeOHj+GSCiiW8NR1RKJIRQ9njTOfXs++X3qByRTA+pSakhfTd9AOmvCzGZhDg8jk70KMzOMK2YGl00TVzImzIyJzHAW6XQaQ6lLejB1UV24MISz579LGotLr5MuZaBMKMKE9hgH9RiI7cJer4ISCs4FpJQQYjt+n3hcE8aVQz28XHqdNJ69WEzWLAeWQ5TlEE09D8zjWKuUUS6vwLIcOK4LjzEIIbbh9/yMn63ZLnyX8dwX2u6mkDEOISUkZRAWAfc4GOfBNNsmlBL+2a3C54FwoS60Xapsl2ouBJTUqDjrqFQqsC0nEH4srXWAX77UF/rZQLiwuHHlrRNyrqVSmjOmuUs185gWUvofCpBSBmsplWZc6E+ECy9fDTjEq/9cj0FtTFCxq3i/WgN1CAj1wBiDUgpbyz/rZx3i+WK8WFwaMP7486/+N/+u4O3yqny7XFbl1TVVrdaU5dqq5rjKcX2IcglRlHqKUBpAKQ3665Wa/FC1xPsPVfz9z7N+49r1n/aN/nzrzMjozb6R0Vt9P94Y6RvL/XZ6tvQ0UZopJorFmUSxWEwUCsVEPl9I5Av/M53PJyanHgVMPZo+/etYLvwfT+UNwHXDMmoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "help",
            "title": "help",
            "src": "/static/b69d0b2408f9a21cc031c536eebc840f/c1b63/help.png",
            "srcSet": ["/static/b69d0b2408f9a21cc031c536eebc840f/5a46d/help.png 300w", "/static/b69d0b2408f9a21cc031c536eebc840f/0a47e/help.png 600w", "/static/b69d0b2408f9a21cc031c536eebc840f/c1b63/help.png 1200w", "/static/b69d0b2408f9a21cc031c536eebc840f/29007/help.png 1600w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This week we launched the `}<a parentName="p" {...{
        "href": "https://help.starswap.xyz/"
      }}><em parentName="a">{`Starswap Help
Center`}</em></a>{` to address common questions and
issues that affect our users. We’ve also published a number of How To
guides, starting with an `}<a parentName="p" {...{
        "href": "https://help.starswap.xyz/en/collections/3033942-layer-2"
      }}><em parentName="a">{`Optimism
collection`}</em></a>{` to
accompany the protocol’s recent launch on Layer 2.`}</p>
    <p>{`We’ll continue adding new content on an ongoing basis and warmly welcome
suggested topics from the community. We will also soon offer direct
access to the Help Center through the application.`}</p>
    <h1 {...{
      "id": "docs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#docs",
        "aria-label": "docs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Docs`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c90d4d160ab77a81c2727cff0bf57ab9/4b39a/docs.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAADSklEQVQ4y02Oy2/jZBTFDVJhRhraJFO/7cROnKS2Pzu2EzuPptM0bb9p00ZULFiDNCMmO1aQFbthBWwKtPQxSfnLQGIBKxYsAKf5zsgZQCx+Oveec+/V5X7/9bdi+tcfUwAZn2e6BKaLe0yXDNP7JabsTTa9Z29Y/l+XK/0sXbAvfvr5F597+fLLk9l8jtn8Dt9dXGJ29yOuX81wO7/D1c0MN6/muPjhGt9+f7nyr2/nK//qdobL61tcXd/g/PwcF5dX+Orrbz7l3n7AH76zbtw/yFXTtXXz77X3zHRtw0rfzdspX/TSgu6njwQvfbTppgXRT9Win+olkpoWSXnZSdfWjfSth8qfD3MVyKXgE47XfCqXu1Cr+0yuDphU2YNUOUQtGGF//BH2Tp7h4PQZjsbPMTp+jrP3P8ZweIZ+6wS2NYRc6jCp3Fmo1g6Ktf6EeyxtUcEIIJe7TNM7TC62oVR6CNvHIOEYcecM/eEpersj9Htj7MTH6Lf24ZoxyoKLkuIxXvcXgt7AY8WZcJtqnYqGB7MWM1JuMlKN4ThdNJMjeNEIcXuM0cEHGI0+xHZ/jCQ6RMfdRegmIGYDhMRMscKFUPTAa3Z20KKS6aJsB8x1fObaPojfhGYS8JoDs9YE8RM4fhvFahO8akPSbJBGE35GELGqEyzkMgGv1yYcr5apZNRRtGwWtRLWCFrwgyZqboQqaaHV6SOMu2jEXThBjHojge3HII0IXiNCIwhZ3XYWemULvGZNOEk1qFqqQDcq7MlgyI5HYwyGB4iSbdTdEIdHI9DRKQ6OTtHsbIOECQYHT9F/MsDO7h56/R1mWtWFblSgaMaEk2SFqpoOVdOZH4TLpN1lcbvDvCBiplVbfR23u6yVdFgjbDGrbrNW0mbZXLvbY1ErZlqxtFA0HbKsvuAEQXwqyQr+gSmqhgxRklceL0rgBRGyokLT9FWW9f8iiBIkWb3PckGUJhwviDRbFCWZaXqRucRjjktWdfZ1prKisnLFYsTzWcWq/udrus5kWWGSrCyyG4IoveA2NnKFfL5Ac7k83eR5WqtvUcuqUs/3aRCGNAwjWioZVBQlumU71HYc6nk+JZ5PXUJovlCguXyB5vOF441cTn0NwXugYwIFyeIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "docs",
            "title": "docs",
            "src": "/static/c90d4d160ab77a81c2727cff0bf57ab9/c1b63/docs.png",
            "srcSet": ["/static/c90d4d160ab77a81c2727cff0bf57ab9/5a46d/docs.png 300w", "/static/c90d4d160ab77a81c2727cff0bf57ab9/0a47e/docs.png 600w", "/static/c90d4d160ab77a81c2727cff0bf57ab9/c1b63/docs.png 1200w", "/static/c90d4d160ab77a81c2727cff0bf57ab9/d61c2/docs.png 1800w", "/static/c90d4d160ab77a81c2727cff0bf57ab9/97a96/docs.png 2400w", "/static/c90d4d160ab77a81c2727cff0bf57ab9/4b39a/docs.png 3104w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Starswap Docs has received a fresh coat of paint!`}</p>
    <p>{`Users can now view and search through documentation and contracts across
all three protocols in one consolidated venue. We have also added
further technical guides around the
`}<a parentName="p" {...{
        "href": "https://docs.starswap.xyz/protocol/guides/swaps/single-swaps"
      }}><em parentName="a">{`Swap`}</em></a>{`
and `}<a parentName="p" {...{
        "href": "https://docs.starswap.xyz/protocol/guides/flash-integrations/inheritance-constructors"
      }}><em parentName="a">{`Flash
Swap`}</em></a>{`
functions in v3.`}</p>
    <p>{`V3 Add Liquidity and SDK guides are coming very soon!`}</p>
    <h1 {...{
      "id": "community-feedback",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#community-feedback",
        "aria-label": "community feedback permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Community Feedback`}</h1>
    <p>{`We are beyond grateful for our engaged community members, who are
consistently willing to volunteer time and provide feedback to improve
our products.`}</p>
    <p>{`The feature updates released today, alongside upcoming updates, are the
direct result of numerous user conversations: a very special shoutout to
`}<a parentName="p" {...{
        "href": "http://twitter.com/nftland"
      }}><em parentName="a">{`Rizzle`}</em></a>{`, Mark Jeffrey,
`}<a parentName="p" {...{
        "href": "http://www.twitter.com/epheph"
      }}><em parentName="a">{`Epheph`}</em></a>{`, `}<a parentName="p" {...{
        "href": "http://twitter.com/defiahab"
      }}><em parentName="a">{`Haye
Chan`}</em></a>{`, Drew Korn, Alex Komorav, Cyrus
Ansari, `}<a parentName="p" {...{
        "href": "/will__price"
      }}><em parentName="a">{`Will Price`}</em></a>{`, Wario, reuptake
and the `}<a parentName="p" {...{
        "href": "https://discord.gg/UKm6BzkJ"
      }}><em parentName="a">{`LP cafe discord`}</em></a>{`.`}</p>
    <p>{`We want to keep hearing from you! As always, the Starswap Labs team can
be found in the community `}<a parentName="p" {...{
        "href": "https://discord.gg/FCfyBSbCU5"
      }}><em parentName="a">{`discord`}</em></a>{` and
on Twitter. If you are willing to volunteer 15 minutes for a user
feedback session, please fill in `}<a parentName="p" {...{
        "href": "https://forms.gle/3gZ6NVQSATHoqz7MA"
      }}><em parentName="a">{`this
form`}</em></a>{` and we will be in contact as
soon as possible.`}</p>
    <hr></hr>
    <p>{`To get involved and stay up to date:`}</p>
    <ul>
      <li parentName="ul">{`Join the Starswap community `}<a parentName="li" {...{
          "href": "https://discord.gg/FCfyBSbCU5"
        }}>{`discord`}</a></li>
      <li parentName="ul">{`Follow Starswap on `}<a parentName="li" {...{
          "href": "/Starswap"
        }}>{`Twitter`}</a></li>
      <li parentName="ul">{`Subscribe to the Starswap `}<a parentName="li" {...{
          "href": "/blog"
        }}>{`blog`}</a></li>
      <li parentName="ul">{`Register as a delegate `}<a parentName="li" {...{
          "href": "http://sybil.org/"
        }}>{`Sybil`}</a></li>
      <li parentName="ul">{`Participate in `}<a parentName="li" {...{
          "href": "http://gov.starswap.xyz/"
        }}>{`Starswap governance`}</a></li>
    </ul>
    <p>{`Starswap Team
🦄`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      